import React from 'react'

import moment from 'moment'

import Image from 'gatsby-image'

import styles from './style.module.scss'

export default ({ post }) => {
    return (
        <div className={styles.post}>
            <div className="wrapper">
                <div className="row">
                    <div className="col-xs-12">
                        <span className={styles.date}>
                            {moment(post.date).format('MMMM Do Y')}
                        </span>
                        <span className={styles.tags}>
                            {post.tags && post.tags.length
                                ? post.tags.map(c => c.name).join(', ')
                                : null}
                        </span>
                    </div>
                    <div className="col-xs-12">
                        <div className={styles.card}>
                            {post.featured_media &&
                            post.featured_media.localFile &&
                            post.featured_media.localFile.childImageSharp ? (
                                <Image
                                    fluid={
                                        post.featured_media.localFile
                                            .childImageSharp.fluid
                                    }
                                    className={styles.image}
                                />
                            ) : null}
                            <h1
                                className={styles.title}
                                dangerouslySetInnerHTML={{ __html: post.title }}
                            />
                            <div
                                className={styles.content}
                                dangerouslySetInnerHTML={{
                                    __html: post.content
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
