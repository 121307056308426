import React from 'react'

import { graphql } from 'gatsby'

import SEO from '../components/seo'

import Layout from '../components/layout'

import Post from '../components/post'

export default ({ data }) => {
    const post = data.allWordpressPost.edges[0].node

    return (
        <Layout
            marketTicker
            breadcrumb={[
                {
                    title: 'Home',
                    link: '/'
                },
                {
                    title: 'Blog',
                    link: '/blog'
                },
                {
                    title: post.title
                }
            ]}
        >
            <SEO
                title={post.yoast_meta.yoast_wpseo_title}
                description={post.yoast_meta.yoast_wpseo_metadesc}
            />
            <Post post={post} />
        </Layout>
    )
}
// allWordpressPost(fields: { slug: { eq: $slug } }) {

export const query = graphql`
    query($slug: String!) {
        allWordpressPost(filter: { slug: { eq: $slug } }) {
            edges {
                node {
                    id
                    slug
                    title
                    content
                    excerpt
                    date
                    yoast_meta {
                        yoast_wpseo_title
                        yoast_wpseo_metadesc
                    }
                    categories {
                        name
                    }
                    tags {
                        name
                    }
                    status

                    featured_media {
                        localFile {
                            childImageSharp {
                                fluid(maxWidth: 900) {
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`
